<template>
  <v-data-table
    :headers="headers"
    :items="openTickets"
    :custom-sort="customSort"
    responsive
    class="last-table-cell-fit-content"
    data-cy="support-open-ticket-table"
  >
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title>
          {{
            $t('support.openTickets')
          }}
        </v-toolbar-title>
      </v-toolbar>
      <v-divider />
    </template>
    <template #item.lastUpdate="{ item }">
      {{ item.lastUpdateDateTime | fromNow(currentLocale) }}
    </template>
    <template #item.actions="{ item }">
      <div class="my-2 my-sm-0 text-right text-no-wrap">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              depressed
              outlined
              color="primary"
              class="ml-auto"
              data-cy="support-open-ticket-table-close-button"
              v-on="on"
              @click="closeTicket(item)"
            >
              {{ closeTicketIcon }}
            </v-icon>
          </template>
          <span>{{ $t('close') }}</span>
        </v-tooltip>
        <EscalateButton
          v-if="!isPriorityMaximal(item.priority)"
          :item="item"
        />
        <router-link :to="{name: ticketModalRouteName, params: {ticketId: item.id}}">
          <v-btn
            rounded
            depressed
            class="ml-2"
            color="primary"
            data-cy="support-ticket-show-details-button"
            :data-cy-id="item.id"
          >
            {{ $t('support.showTicketDetails') }}
          </v-btn>
        </router-link>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import {
  TICKET_STATE_NEW,
  TICKET_STATE_ON_CLIENT,
  EDIT_ICON,
  CHECKBOX_MARKED_ICON
} from '../../../constants/app'
import { OPEN_TICKET_HEADERS, PRIORITY_URGENT } from '../../../constants/support'
import { ROUTE_TICKET_MODAL } from '@/constants/routeNames'
import { comparePriorities } from '@/store/modules/support/service'
import EscalateButton from '@/components/material/support/escalate/EscalateButton.vue'

export default {
  components: { EscalateButton },
  filters: {
    fromNow: function (date, locale) {
      return moment(date)
        .locale(locale)
        .fromNow()
    }
  },
  data: () => {
    return {
      stateNew: TICKET_STATE_NEW,
      ticketModalRouteName: ROUTE_TICKET_MODAL,
      editIcon: EDIT_ICON,
      closeTicketIcon: CHECKBOX_MARKED_ICON
    }
  },
  computed: {
    ...mapGetters('support', { openTicketsGetter: 'openTickets' }),
    openTickets () {
      const tickets = this.openTicketsGetter(this.$route.params.clientId)
      const ticketsOnClient = tickets.filter(ticket => ticket.state === TICKET_STATE_ON_CLIENT)
      const ticketsNotOnClient = tickets.filter(ticket => ticket.state !== TICKET_STATE_ON_CLIENT)
      return ticketsOnClient.concat(ticketsNotOnClient)
    },
    currentLocale () {
      return this.$route.params.locale
    },
    headers () {
      return OPEN_TICKET_HEADERS.map(header => {
        header.text = this.$i18n.t(header.text)
        return header
      })
    }
  },
  methods: {
    ...mapActions('support', ['closeTicket']),
    isPriorityMaximal (priority) {
      return priority === PRIORITY_URGENT
    },
    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'priority') {
          if (isDesc[0]) {
            return comparePriorities(b.priority, a.priority)
          }
          return comparePriorities(a.priority, b.priority)
        }
        if (index[0] === 'lastUpdate') {
          if (isDesc[0]) {
            return Date.parse(b.lastUpdateDateTime) - Date.parse(a.lastUpdateDateTime)
          }
          return Date.parse(a.lastUpdateDateTime) - Date.parse(b.lastUpdateDateTime)
        }
        if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1
        }
        return b[index] < a[index] ? -1 : 1
      })
      return items
    }
  }
}
</script>
