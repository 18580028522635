<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
    max-width="550"
  >
    <template #activator="{ on: dialog }">
      <v-tooltip
        top
      >
        <template #activator="{ on: tooltip }">
          <v-icon
            depressed
            outlined
            color="primary"
            class="ml-2"
            data-cy="support-escalate-ticket"
            v-on="{ ...tooltip, ...dialog }"
          >
            {{ escalateTicketIcon }}
          </v-icon>
        </template>
        <span>{{ $t('support.escalate') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title
        class="d-flex flex-row flex-nowrap align-start justify-space-between"
      >
        <div class="headline">
          {{ $t('support.escalatePromptModal.title') }}
        </div>
        <v-icon
          data-cy="escalate-modal-close"
          @click="closeDialog"
        >
          {{ closeIcon }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <p>{{ $t('support.escalatePromptModal.subTitle') }}</p>
        <ul class="mb-4 font-weight-bold">
          <li>{{ $t('support.escalatePromptModal.criticalItemsList.outages') }}</li>
          <li>{{ $t('support.escalatePromptModal.criticalItemsList.serviceDegradation') }}</li>
          <li>{{ $t('support.escalatePromptModal.criticalItemsList.other') }}</li>
          <li>{{ $t('support.escalatePromptModal.criticalItemsList.significantDelays') }}</li>
        </ul>
        <p>{{ $t('support.escalatePromptModal.agreements') }}</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          color="primary"
          depressed=""
          data-cy="escalate-dialog-ok"
          @click="escalateTicket(item)"
        >
          {{ $t('ok') }}
        </v-btn>
        <v-btn
          rounded
          depressed=""
          data-cy="escalate-dialog-cancel"
          @click="closeDialog()"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { CLOSE_ICON, EXCLAMATION_ICON } from '@/constants/app'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      dialog: false,
      escalateTicketIcon: EXCLAMATION_ICON,
      closeIcon: CLOSE_ICON
    }
  },
  methods: {
    ...mapActions('support', ['escalateTicket']),
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
